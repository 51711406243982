import React from 'react';
import css from './CategoriesNavMobile.module.css';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from 'react-intl';
import { listingFields } from '../../config/configListing';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CategoriesNavMobile = props => {
  const [showAllCategories, setShowAllCategories] = useState(false);

  const categoriesOptions = listingFields?.find(i => i?.key === 'category')?.enumOptions || [];

  const history = useHistory();
  if (categoriesOptions?.length === 0) {
    return null;
  }

  return (

    <div className={css.wrapper}>

      <div className={css.allWrapper}
        onClick={(e) => setShowAllCategories(!showAllCategories)}>
        <p className={css.header}>
          <FormattedMessage id="CategoriesNavMobile.searchByCategory" />
        </p>
        {showAllCategories ? <ExpandLessIcon className={css.icon} /> : <ExpandMoreIcon className={css.icon} />}
      </div>

      {showAllCategories && (
        <div className={css.allCategoriesWrapper}>

          {categoriesOptions.map(c => {
            return (
              <div className='row'>
                <div
                  className={css.item}
                  onClick={() => history.push(`/s?pub_category=${c?.option}`)}
                >
                  <div className={css.itemAllWrapper}>
                    <span className={css.label}>
                      {c?.label}
                    </span>

                    <ArrowForwardIosIcon />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}


    </div>
  );
};

export default CategoriesNavMobile;
