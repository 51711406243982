

import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';


// ================ Action types ================ //

export const FETCH_FAVORITES_REQUEST = 'app/FavoritesPage/FETCH_FAVORITES_REQUEST';
export const FETCH_FAVORITES_SUCCESS = 'app/FavoritesPage/FETCH_FAVORITES_SUCCESS';
export const FETCH_FAVORITES_ERROR = 'app/FavoritesPage/FETCH_FAVORITES_ERROR';

// ================ Reducer ================ //

const initialState = {
    queryFavoritesInProgress: false,
    queryFavoritesError: null,
    favorites: [],
};


const favoritesPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_FAVORITES_REQUEST:
            return {
                ...state,
                queryFavoritesInProgress: true,
                queryFavoritesError: null,
                favorites: [],
            };
        case FETCH_FAVORITES_SUCCESS:
            return {
                ...state,
                favorites: payload.favorites,
                queryFavoritesInProgress: false,
            };
        case FETCH_FAVORITES_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, queryFavoritesInProgress: false, queryFavoritesError: payload };

        default:
            return state;
    }
};

export default favoritesPageReducer;

// ================ Selectors ================ //
export const queryFavoritesRequest = queryParams => ({
    type: FETCH_FAVORITES_REQUEST,
});

export const queryFavoritesSuccess = response => ({
    type: FETCH_FAVORITES_SUCCESS,
    payload: { favorites: response },
});

export const queryFavoritesError = e => ({
    type: FETCH_FAVORITES_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

    dispatch(queryFavoritesRequest());

    return sdk.currentUser
        .show()
        .then(res => {

            const userPrivateData = res.data.data.attributes.profile.privateData;

            if (userPrivateData.favorites) {
                const favoritesIds = userPrivateData.favorites;
                const {
                    aspectWidth = 1,
                    aspectHeight = 1,
                    variantPrefix = 'listing-card',
                } = config.listing;
                const aspectRatio = aspectHeight / aspectWidth;


                const promisesArray = favoritesIds.map(listingId => {
                    return sdk.listings
                        .show({
                            id: listingId,
                            include: ['author', 'images'],
                            'fields.image': [
                                'variants.scaled-small',
                                'variants.scaled-medium',
                                `variants.${variantPrefix}`,
                                `variants.${variantPrefix}-2x`,
                            ],
                            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
                            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
                            'limit.images': 1,
                        })
                        .then(resp => {
                            let rowListing = resp.data.data;

                            const imagesArray = resp.data.included.filter(i => {
                                return i.type === 'image';
                            });

                            if (imagesArray.length === 0) {
                                return rowListing;
                            }

                            const firstImageId = resp.data.data.relationships.images.data[0].id.uuid;

                            const finalImagesArray = [
                                imagesArray.find(i => {
                                    return i.id.uuid === firstImageId;
                                }),
                            ];

                            rowListing.images = finalImagesArray;

                            return rowListing;
                        })
                        .catch(error => console.log(error.stack));
                });

                Promise.all(promisesArray)
                    .then(resp => {
                        const flattenJustFetchedDatesArray = resp.flat();
                        dispatch(queryFavoritesSuccess(flattenJustFetchedDatesArray));
                        return resp;
                    })
                    .catch(e => {
                        dispatch(queryFavoritesError(storableError(e)));
                        throw e;
                    });
            }
        })
        .catch(e => {
            dispatch(queryFavoritesError(storableError(e)));
            throw e;
        });
};