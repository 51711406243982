/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'listingVariant',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'listing', label: 'Selling for a fixed price' },
      { option: 'auction', label: 'Auction' },
    ],
    filterConfig: {
      indexForSearch: false,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a type.',
    },
  },

  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    "enumOptions": [
      { "option": "accesorios_para_animales", "label": "Accesorios para Animales" },
  { "option": "accesorios_para_vehiculos", "label": "Accesorios para Vehículos" },
  { "option": "antiguedades_y_arte", "label": "Antigüedades & Arte" },
  { "option": "audio_tv_video", "label": "Audio, TV & Video" },
  { "option": "billetes_y_bonos", "label": "Billetes & Bonos" },
  { "option": "bricolaje_y_jardin", "label": "Bricolaje & Jardín" },
  { "option": "oficina_y_comercios", "label": "Oficina & Comercios" },
  { "option": "coleccionismo_y_rarezas", "label": "Colecciones & Rarezas" },
  { "option": "infantil_y_bebe", "label": "Infantil & Bebé" },
  { "option": "peliculas_y_series_tv", "label": "Películas & Series TV" },
  { "option": "videojuegos_y_consolas", "label": "Videojuegos & Consolas" },
  { "option": "juguetes_y_bricolaje", "label": "Juguetes & Bricolaje" },
  { "option": "libros_y_bd", "label": "Libros & BD" },
  { "option": "moda", "label": "Moda" },
  { "option": "modelismo_y_hobby", "label": "Modelismo & Hobby" },
  { "option": "monedas", "label": "Monedas" },
  { "option": "relojes_y_joyas", "label": "Relojes & Joyas" },
  { "option": "musica_y_instrumentos", "label": "Música & Instrumentos" },
  { "option": "menaje_y_hogar", "label": "Menaje & Hogar" },
  { "option": "ordenadores_y_redes", "label": "Ordenadores & Redes" },
  { "option": "fotografia_y_optica", "label": "Fotografía & Óptica" },
  { "option": "cuidado_y_cosmetica", "label": "Cuidado & Cosmética" },
  { "option": "deporte", "label": "Deporte" },
  { "option": "sellos", "label": "Sellos" },
  { "option": "telefonia_y_radiocomunicacion", "label": "Telefonía & Radiocomunicación" },
  { "option": "vinos_y_gastronomia", "label": "Vinos & Gastronomía" },
  { "option": "vehiculos", "label": "Vehículos" }
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },

  {
    key: 'subcategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [



      
      { "option": "acuario", "label": "Acuario", "category": "accesorios_para_animales" },
      { "option": "arboles_para_gatos", "label": "Árboles para Gatos", "category": "accesorios_para_animales" },
      { "option": "otros_accesorios_para_animales", "label": "Otros Accesorios para Animales", "category": "accesorios_para_animales" },
      { "option": "bridas_licoles", "label": "Bridas, Licoles", "category": "accesorios_para_animales" },
      { "option": "jaulas_transportes_seguridad", "label": "Jaulas, Transporte & Seguridad", "category": "accesorios_para_animales" },
      { "option": "calentadores", "label": "Calentadores", "category": "accesorios_para_animales" },
      { "option": "collares_y_correas", "label": "Collar y Correa", "category": "accesorios_para_animales" },
      { "option": "mantas", "label": "Mantas", "category": "accesorios_para_animales" },
      { "option": "decoraciones", "label": "Decoraciones", "category": "accesorios_para_animales" },
      { "option": "iluminacion", "label": "Iluminación", "category": "accesorios_para_animales" },
      { "option": "equipamiento_para_establo", "label": "Equipamiento para Establo", "category": "accesorios_para_animales" },
      { "option": "filtros", "label": "Filtros", "category": "accesorios_para_animales" },
      { "option": "vendas_y_vendajes", "label": "Vendas y Vendajes", "category": "accesorios_para_animales" },
      { "option": "juguetes_para_animales", "label": "Juguetes", "category": "accesorios_para_animales" },
      { "option": "libros_sobre_animales", "label": "Libros", "category": "accesorios_para_animales" },
      { "option": "casas_y_camas_para_animales", "label": "Casas, Camas", "category": "accesorios_para_animales" },
      { "option": "alimento_para_animales", "label": "Alimento", "category": "accesorios_para_animales" },
      { "option": "plantas_para_acuario", "label": "Plantas", "category": "accesorios_para_animales" },
      { "option": "placas_y_medallones", "label": "Placas, Medallones", "category": "accesorios_para_animales" },
      { "option": "bombas_para_acuario", "label": "Bombas", "category": "accesorios_para_animales" },
      { "option": "productos_de_cuidado_para_animales", "label": "Productos de Cuidado", "category": "accesorios_para_animales" },
      { "option": "sillas_de_montar_y_accesorios", "label": "Sillas de Montar, Accesorios", "category": "accesorios_para_animales" },
      { "option": "terrarios", "label": "Terrarios", "category": "accesorios_para_animales" },
      { "option": "ropa_para_animales", "label": "Ropa", "category": "accesorios_para_animales" },
    


        
      { "option": "accesorios_para_vehiculos_utilitarios", "label": "Accesorios para Vehículos Utilitarios", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_auto", "label": "Accesorios Auto", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_de_camping", "label": "Accesorios de Camping", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_motos", "label": "Accesorios Motos", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_para_barcos", "label": "Accesorios para Barcos", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_para_ciclomotores", "label": "Accesorios para Ciclomotores", "category": "accesorios_para_vehiculos" },
      { "option": "accesorios_remolques", "label": "Accesorios Remolques", "category": "accesorios_para_vehiculos" },
      { "option": "alarmas", "label": "Alarmas", "category": "accesorios_para_vehiculos" },
      { "option": "antidemarrages", "label": "Antidemarrages", "category": "accesorios_para_vehiculos" },
      { "option": "articulos_para_fan", "label": "Artículos para Fan", "category": "accesorios_para_vehiculos" },
      { "option": "otros_accesorios_para_vehiculo", "label": "Otros Accesorios p. Vehículo", "category": "accesorios_para_vehiculos" },
      { "option": "cascos_y_proteccion", "label": "Cascos y Protección", "category": "accesorios_para_vehiculos" },
      { "option": "electrico", "label": "Eléctrico", "category": "accesorios_para_vehiculos" },
      { "option": "equipamientos_de_talleres", "label": "Equipamientos de Talleres", "category": "accesorios_para_vehiculos" },
      { "option": "hi-fi_para_auto", "label": "Hi-fi para Auto", "category": "accesorios_para_vehiculos" },
      { "option": "aceites_y_aditivos", "label": "Aceites y Aditivos", "category": "accesorios_para_vehiculos" },
      { "option": "kits_cierre_centralizado", "label": "Kits Cierre Centralizado", "category": "accesorios_para_vehiculos" },
      { "option": "motores", "label": "Motores", "category": "accesorios_para_vehiculos" },
      { "option": "navegacion", "label": "Navegación", "category": "accesorios_para_vehiculos" },
      { "option": "focos_y_luces", "label": "Focos y Luces", "category": "accesorios_para_vehiculos" },
      { "option": "piezas_de_repuesto_auto", "label": "Piezas de Repuesto Auto", "category": "accesorios_para_vehiculos" },
      { "option": "piezas_de_repuesto_moto", "label": "Piezas de Repuesto Moto", "category": "accesorios_para_vehiculos" },
      { "option": "piezas_para_scooter", "label": "Piezas para Scooter", "category": "accesorios_para_vehiculos" },
      { "option": "neumaticos_llantas_y_ruedas", "label": "Neumáticos, Llantas & Ruedas", "category": "accesorios_para_vehiculos" },
      { "option": "productos_de_limpieza_y_mantenimiento", "label": "Productos de Limpieza & Mantenimiento", "category": "accesorios_para_vehiculos" },
      { "option": "servicios_y_reparaciones", "label": "Servicios y Reparaciones", "category": "accesorios_para_vehiculos" },
      { "option": "equipamiento_moto", "label": "Equipamiento Moto", "category": "accesorios_para_vehiculos" },
      { "option": "tuning_auto", "label": "Tuning Auto", "category": "accesorios_para_vehiculos" },
      { "option": "escapes", "label": "Escapes", "category": "accesorios_para_vehiculos" },
            
        


          
      { "option": "aparatos_antiguos", "label": "Aparatos Antiguos", "category": "antiguedades_y_arte" },
      { "option": "arte_internacional", "label": "Arte Internacional", "category": "antiguedades_y_arte" },
      { "option": "platos", "label": "Platos", "category": "antiguedades_y_arte" },
      { "option": "otras_antiguedades_o_arte", "label": "Otras Antigüedades o Arte", "category": "antiguedades_y_arte" },
      { "option": "joyeria_antigua", "label": "Joyería Antigua", "category": "antiguedades_y_arte" },
      { "option": "candelabros", "label": "Candelabros", "category": "antiguedades_y_arte" },
      { "option": "botellas", "label": "Botellas", "category": "antiguedades_y_arte" },
      { "option": "cafetera_tetera_porcelana", "label": "Cafetera, Tetera Porcelana", "category": "antiguedades_y_arte" },
      { "option": "garrafas", "label": "Garrafas", "category": "antiguedades_y_arte" },
      { "option": "copas_y_terrinas", "label": "Copas & Terrinas", "category": "antiguedades_y_arte" },
      { "option": "cubiertos", "label": "Cubiertos", "category": "antiguedades_y_arte" },
      { "option": "aguafuertes_y_dibujos", "label": "Aguafuertes & Dibujos", "category": "antiguedades_y_arte" },
      { "option": "figurillas", "label": "Figurillas", "category": "antiguedades_y_arte" },
      { "option": "grabados", "label": "Grabados", "category": "antiguedades_y_arte" },
      { "option": "instrumentos_musicales_antiguos", "label": "Instrumentos Musicales Antiguos", "category": "antiguedades_y_arte" },
      { "option": "juguetes_antiguos", "label": "Juguetes Antiguos", "category": "antiguedades_y_arte" },
      { "option": "ropa_de_casa_antigua", "label": "Ropa de Casa", "category": "antiguedades_y_arte" },
      { "option": "libros_de_antiguedades", "label": "Libros de Antigüedades", "category": "antiguedades_y_arte" },
      { "option": "material_para_artistas", "label": "Material para Artistas", "category": "antiguedades_y_arte" },
      { "option": "muebles_accesorios_antiguos", "label": "Muebles, Accesorios Antiguos", "category": "antiguedades_y_arte" },
      { "option": "pinturas_y_artes_graficas", "label": "Pinturas y Artes Gráficas", "category": "antiguedades_y_arte" },
      { "option": "fotos_y_posters", "label": "Fotos & Posters", "category": "antiguedades_y_arte" },
      { "option": "macetas", "label": "Macetas", "category": "antiguedades_y_arte" },
      { "option": "munecas_y_accesorios", "label": "Muñecas & Accesorios", "category": "antiguedades_y_arte" },
      { "option": "esculturas", "label": "Esculturas", "category": "antiguedades_y_arte" },
      { "option": "servicios_porcelana", "label": "Servicios en Porcelana", "category": "antiguedades_y_arte" },
      { "option": "jarrones", "label": "Jarrones", "category": "antiguedades_y_arte" },
      { "option": "vasos", "label": "Vasos", "category": "antiguedades_y_arte" },
      { "option": "ropa_antigua", "label": "Ropa Antigua", "category": "antiguedades_y_arte" },
    
          
          
            
      { "option": "accesorios", "label": "Accesorios", "category": "audio_tv_video" },
      { "option": "aparatos_tv", "label": "Aparatos TV", "category": "audio_tv_video" },
      { "option": "aparatos_de_cinta", "label": "Aparatos de Cinta", "category": "audio_tv_video" },
      { "option": "camaras_de_video", "label": "Cámaras de Vídeo", "category": "audio_tv_video" },
      { "option": "auriculares", "label": "Auriculares", "category": "audio_tv_video" },
      { "option": "cadenas_estereo", "label": "Cadenas Estéreo", "category": "audio_tv_video" },
      { "option": "componentes_hi-fi", "label": "Componentes Hi-fi", "category": "audio_tv_video" },
      { "option": "conversores", "label": "Conversores", "category": "audio_tv_video" },
      { "option": "electronica", "label": "Electrónica", "category": "audio_tv_video" },
      { "option": "grabadoras", "label": "Grabadoras", "category": "audio_tv_video" },
      { "option": "peliculas_8_16_35mm_y_super_8", "label": "Películas 8, 16, 35mm & Super 8", "category": "audio_tv_video" },
      { "option": "altavoces", "label": "Altavoces", "category": "audio_tv_video" },
      { "option": "cine_en_casa", "label": "Cine en Casa", "category": "audio_tv_video" },
      { "option": "karaokes", "label": "Karaokes", "category": "audio_tv_video" },
      { "option": "reproductor_media_portatil", "label": "Reproductor Media Portátil", "category": "audio_tv_video" },
      { "option": "reproductor_multimedia", "label": "Reproductor Multimedia", "category": "audio_tv_video" },
      { "option": "lectores_dvd", "label": "Lectores DVD", "category": "audio_tv_video" },
      { "option": "microfonos", "label": "Micrófonos", "category": "audio_tv_video" },
      { "option": "minidiscos", "label": "Minidiscos", "category": "audio_tv_video" },
      { "option": "radios_y_grabadoras", "label": "Radios & Grabadoras", "category": "audio_tv_video" },
      { "option": "rarezas_hi-fi", "label": "Rarezas Hi-fi", "category": "audio_tv_video" },
      { "option": "receptores", "label": "Receptores", "category": "audio_tv_video" },
      { "option": "streamer", "label": "Streamer", "category": "audio_tv_video" },
      { "option": "sistemas_satelite_y_paytv", "label": "Sistemas Satélite & PayTV", "category": "audio_tv_video" },
      { "option": "videoproyectores", "label": "Videoproyectores", "category": "audio_tv_video" },
    
              
      { "option": "entradas", "label": "Entradas", "category": "billetes_y_bonos" },
      { "option": "bonos", "label": "Bonos", "category": "billetes_y_bonos" },
      { "option": "sellos_y_puntos", "label": "Sellos & Puntos", "category": "billetes_y_bonos" },


    
      { "option": "accesorios_para_parrilla", "label": "Accesorios para Parrilla", "category": "bricolaje_y_jardin" },
      { "option": "accesorios_para_el_jardin", "label": "Accesorios para el Jardín", "category": "bricolaje_y_jardin" },
      { "option": "alarma_para_casa", "label": "Alarma para Casa", "category": "bricolaje_y_jardin" },
      { "option": "aparatos_de_limpieza", "label": "Aparatos de Limpieza", "category": "bricolaje_y_jardin" },
      { "option": "balancoire", "label": "Balançoire", "category": "bricolaje_y_jardin" },
      { "option": "chimenea", "label": "Chimenea", "category": "bricolaje_y_jardin" },
      { "option": "decoraciones_para_el_jardin", "label": "Decoraciones para el Jardín", "category": "bricolaje_y_jardin" },
      { "option": "instalaciones_para_la_casa", "label": "Instalaciones para la Casa", "category": "bricolaje_y_jardin" },
      { "option": "materiales_de_construccion", "label": "Materiales de Construcción", "category": "bricolaje_y_jardin" },
      { "option": "material_electrico", "label": "Material Eléctrico", "category": "bricolaje_y_jardin" },
      { "option": "muebles_de_jardin", "label": "Muebles de Jardín", "category": "bricolaje_y_jardin" },
      { "option": "herramientas", "label": "Herramientas", "category": "bricolaje_y_jardin" },
      { "option": "herramientas_de_jardin", "label": "Herramientas de Jardín", "category": "bricolaje_y_jardin" },
      { "option": "panel_solar", "label": "Panel Solar", "category": "bricolaje_y_jardin" },
      { "option": "paredes_y_techos", "label": "Paredes & Techos", "category": "bricolaje_y_jardin" },
      { "option": "pintura_y_barniz", "label": "Pintura & Barniz", "category": "bricolaje_y_jardin" },
      { "option": "plantas_y_semillas", "label": "Plantas & Semillas", "category": "bricolaje_y_jardin" },
      { "option": "ferreteria", "label": "Ferretería", "category": "bricolaje_y_jardin" },
      { "option": "revestimiento_de_suelo", "label": "Revestimiento de Suelo", "category": "bricolaje_y_jardin" },
      { "option": "sanitario_bano_y_cocina", "label": "Sanitario, Baño y Cocina", "category": "bricolaje_y_jardin" },
      { "option": "wellness", "label": "Wellness", "category": "bricolaje_y_jardin" },
    
                
                  
      { "option": "agricultura_foresteria_y_construccion", "label": "Agricultura, Forestal y Construcción", "category": "oficina_y_comercios" },
      { "option": "otros_articulos_oficina_y_comercios", "label": "Otros Artículos Oficina & Comercios", "category": "oficina_y_comercios" },
      { "option": "otros_oficios", "label": "Otros Oficios", "category": "oficina_y_comercios" },
      { "option": "calendario_y_agenda", "label": "Calendario & Agenda", "category": "oficina_y_comercios" },
      { "option": "dibujo", "label": "Dibujo", "category": "oficina_y_comercios" },
      { "option": "suministros_de_oficina", "label": "Suministros de Oficina", "category": "oficina_y_comercios" },
      { "option": "suministros_escolares", "label": "Suministros Escolares", "category": "oficina_y_comercios" },
      { "option": "fiestas_de_empresas_y_eventos", "label": "Fiestas de Empresas & Eventos", "category": "oficina_y_comercios" },
      { "option": "gastronomia", "label": "Gastronomía", "category": "oficina_y_comercios" },
      { "option": "mayorista", "label": "Mayorista", "category": "oficina_y_comercios" },
      { "option": "industria_y_electronica", "label": "Industria & Electrónica", "category": "oficina_y_comercios" },
      { "option": "mobiliario_de_oficina", "label": "Mobiliario de Oficina", "category": "oficina_y_comercios" },
      { "option": "papeleria", "label": "Papelería", "category": "oficina_y_comercios" },
      { "option": "productos_para_discapacitados", "label": "Productos para Discapacitados", "category": "oficina_y_comercios" },
      { "option": "tecnologia_de_oficina", "label": "Tecnología de Oficina", "category": "oficina_y_comercios" },
      { "option": "todas_las_maquinas", "label": "Todas las Máquinas", "category": "oficina_y_comercios" },
      { "option": "ropa_y_proteccion_de_trabajo", "label": "Ropa & Protección de Trabajo", "category": "oficina_y_comercios" },
    
                   
            
                    
                    
      { "option": "agricultura", "label": "Agricultura", "category": "coleccionismo_y_rarezas" },
      { "option": "albumes", "label": "Álbumes", "category": "coleccionismo_y_rarezas" },
      { "option": "ejercito", "label": "Ejército", "category": "coleccionismo_y_rarezas" },
      { "option": "articulos_de_publicidad", "label": "Artículos de Publicidad", "category": "coleccionismo_y_rarezas" },
      { "option": "autografos", "label": "Autógrafos", "category": "coleccionismo_y_rarezas" },
      { "option": "otras_colecciones_y_rarezas", "label": "Otras Colecciones & Rarezas", "category": "coleccionismo_y_rarezas" },
      { "option": "aviacion", "label": "Aviación", "category": "coleccionismo_y_rarezas" },
      { "option": "canicas", "label": "Canicas", "category": "coleccionismo_y_rarezas" },
      { "option": "cervezas_vinos_y_espirituosos", "label": "Cervezas, Vinos & Espirituosos", "category": "coleccionismo_y_rarezas" },
      { "option": "cajas_de_musica", "label": "Cajas de Música", "category": "coleccionismo_y_rarezas" },
      { "option": "tarjetas_telefonicas", "label": "Tarjetas Telefónicas", "category": "coleccionismo_y_rarezas" },
      { "option": "mapas_geograficos_y_atlas", "label": "Mapas Geográficos & Atlas", "category": "coleccionismo_y_rarezas" },
      { "option": "postales", "label": "Postales", "category": "coleccionismo_y_rarezas" },
      { "option": "cartas_para_coleccionar", "label": "Cartas para Coleccionar", "category": "coleccionismo_y_rarezas" },
      { "option": "ferrocarriles", "label": "Ferrocarriles", "category": "coleccionismo_y_rarezas" },
      { "option": "campanas_y_cencerros", "label": "Campanas & Cencerros", "category": "coleccionismo_y_rarezas" },
      { "option": "navajas", "label": "Navajas", "category": "coleccionismo_y_rarezas" },
      { "option": "decoraciones_y_medallas", "label": "Decoraciones & Medallas", "category": "coleccionismo_y_rarezas" },
      { "option": "dedales", "label": "Dedales", "category": "coleccionismo_y_rarezas" },
      { "option": "letreros", "label": "Letreros", "category": "coleccionismo_y_rarezas" },
      { "option": "figuritas", "label": "Figuritas", "category": "coleccionismo_y_rarezas" },
      { "option": "frascos", "label": "Frascos", "category": "coleccionismo_y_rarezas" },
      { "option": "pinball", "label": "Pinball", "category": "coleccionismo_y_rarezas" },
      { "option": "fosiles", "label": "Fósiles", "category": "coleccionismo_y_rarezas" },
      { "option": "insignias", "label": "Insignias", "category": "coleccionismo_y_rarezas" },
      { "option": "jukebox", "label": "Jukebox", "category": "coleccionismo_y_rarezas" },
      { "option": "maquinas_tragaperras", "label": "Máquinas Tragaperras", "category": "coleccionismo_y_rarezas" },
      { "option": "minerales_y_litoterapia", "label": "Minerales & Litoterapia", "category": "coleccionismo_y_rarezas" },
      { "option": "mundo_de_los_animales", "label": "Mundo de los Animales", "category": "coleccionismo_y_rarezas" },
      { "option": "menaje_y_cocina", "label": "Menaje & Cocina", "category": "coleccionismo_y_rarezas" },
      { "option": "operculos", "label": "Opercules", "category": "coleccionismo_y_rarezas" },
      { "option": "pins", "label": "Pins", "category": "coleccionismo_y_rarezas" },
      { "option": "plaquetas_y_badges", "label": "Plaquetas & Badges", "category": "coleccionismo_y_rarezas" },
      { "option": "policia", "label": "Policía", "category": "coleccionismo_y_rarezas" },
      { "option": "bomberos", "label": "Bomberos", "category": "coleccionismo_y_rarezas" },
      { "option": "llaveros", "label": "Llaveros", "category": "coleccionismo_y_rarezas" },
      { "option": "reclames_y_publicidades", "label": "Reclames & Publicidades", "category": "coleccionismo_y_rarezas" },
      { "option": "huchas", "label": "Huchas", "category": "coleccionismo_y_rarezas" },
      { "option": "uniformes", "label": "Uniformes", "category": "coleccionismo_y_rarezas" },
    
                      
      { "option": "accesorios_para_cochecitos", "label": "Accesorios para Cochecitos", "category": "infantil_y_bebe" },
      { "option": "otros_para_nino_y_bebe", "label": "Otros para Niño & Bebé", "category": "infantil_y_bebe" },
      { "option": "gorros_y_bufandas", "label": "Gorros & Bufandas", "category": "infantil_y_bebe" },
      { "option": "calcetines_para_ninos", "label": "Calcetines para Niños", "category": "infantil_y_bebe" },
      { "option": "zapatos_para_ninas", "label": "Zapatos para Niñas", "category": "infantil_y_bebe" },
      { "option": "zapatos_para_ninos", "label": "Zapatos para Niños", "category": "infantil_y_bebe" },
      { "option": "disfraces", "label": "Disfraces", "category": "infantil_y_bebe" },
      { "option": "equipo_para_bebe_y_puericultura", "label": "Equipo para Bebé & Puericultura", "category": "infantil_y_bebe" },
      { "option": "guantes", "label": "Guantes", "category": "infantil_y_bebe" },
      { "option": "camas_y_muebles_para_ninos", "label": "Camas & Muebles para Niños", "category": "infantil_y_bebe" },
      { "option": "cochecito", "label": "Cochecito", "category": "infantil_y_bebe" },
      { "option": "bolsas_y_mochilas", "label": "Bolsas & Mochilas", "category": "infantil_y_bebe" },
      { "option": "sillas_para_auto", "label": "Sillas para Auto", "category": "infantil_y_bebe" },
      { "option": "ropa_para_ninas", "label": "Ropa para Niñas", "category": "infantil_y_bebe" },
      { "option": "ropa_para_ninos", "label": "Ropa para Niños", "category": "infantil_y_bebe" },
      { "option": "ropa_para_bebes", "label": "Ropa para Bebés", "category": "infantil_y_bebe" },
    
                        
      { "option": "otros_peliculas_y_series_tv", "label": "Otros Películas & Series TV", "category": "peliculas_y_series_tv" },
      { "option": "documentales", "label": "Documentales", "category": "peliculas_y_series_tv" },
      { "option": "peliculas", "label": "Películas", "category": "peliculas_y_series_tv" },
      { "option": "peliculas_concierto_y_musica", "label": "Películas Concierto & Música", "category": "peliculas_y_series_tv" },
      { "option": "series_tv", "label": "Series TV", "category": "peliculas_y_series_tv" },
      { "option": "todos_los_dvds", "label": "Todos los DVDs", "category": "peliculas_y_series_tv" },
      { "option": "todas_las_vhs", "label": "Todas las VHS", "category": "peliculas_y_series_tv" }
    ,
                        
      { "option": "accesorios", "label": "Accesorios", "category": "videojuegos_y_consolas" },
      { "option": "consolas", "label": "Consolas", "category": "videojuegos_y_consolas" },
      { "option": "juegos", "label": "Juegos", "category": "videojuegos_y_consolas" },
      { "option": "articulos_para_fans_de_videojuegos", "label": "Artículos para Fans de Videojuegos", "category": "videojuegos_y_consolas" },
    
                          
      { "option": "otros_juguetes_y_bricolaje", "label": "Otros Juguetes & Bricolaje", "category": "juguetes_y_bricolaje" },
      { "option": "otros_para_munecas", "label": "Otros para Muñecas", "category": "juguetes_y_bricolaje" },
      { "option": "bricolaje_y_costura", "label": "Bricolaje y Costura", "category": "juguetes_y_bricolaje" },
      { "option": "juegos", "label": "Juegos", "category": "juguetes_y_bricolaje" },
      { "option": "juegos_de_construccion", "label": "Juegos de Construcción", "category": "juguetes_y_bricolaje" },
      { "option": "juguetes", "label": "Juguetes", "category": "juguetes_y_bricolaje" },
      { "option": "juguetes_bebe", "label": "Juguetes Bebé", "category": "juguetes_y_bricolaje" },
      { "option": "magia", "label": "Magia", "category": "juguetes_y_bricolaje" },
      { "option": "casas_y_muebles_de_munecas", "label": "Casas & Muebles de Muñecas", "category": "juguetes_y_bricolaje" },
      { "option": "munecas", "label": "Muñecas", "category": "juguetes_y_bricolaje" },
      { "option": "puzzles", "label": "Puzzles", "category": "juguetes_y_bricolaje" },
      { "option": "tapetes_de_juego", "label": "Tapetes de Juego", "category": "juguetes_y_bricolaje" },
      { "option": "coches_para_munecas", "label": "Coches para Muñecas", "category": "juguetes_y_bricolaje" },
      { "option": "vehiculos_para_ninos", "label": "Vehículos para Niños", "category": "juguetes_y_bricolaje" }
    ,
                                                 
                          
      { "option": "arte_y_cultura", "label": "Arte & Cultura", "category": "libros_y_bd" },
      { "option": "comics", "label": "Cómics", "category": "libros_y_bd" },
      { "option": "colecciones_y_series", "label": "Colecciones & Series", "category": "libros_y_bd" },
      { "option": "cocina", "label": "Cocina", "category": "libros_y_bd" },
      { "option": "guias_turisticas", "label": "Guías Turísticas", "category": "libros_y_bd" },
      { "option": "libros", "label": "Libros", "category": "libros_y_bd" },
      { "option": "libros_audio", "label": "Libros Audio", "category": "libros_y_bd" },
      { "option": "libros_de_actualidad", "label": "Libros de Actualidad", "category": "libros_y_bd" },
      { "option": "libros_escolares", "label": "Libros Escolares", "category": "libros_y_bd" },
      { "option": "libros_especializados", "label": "Libros Especializados", "category": "libros_y_bd" },
      { "option": "libros_albums", "label": "Libros-Álbumes", "category": "libros_y_bd" },
      { "option": "para_ninos_y_juventud", "label": "Para Niños & Juventud", "category": "libros_y_bd" },
      { "option": "religion_y_teologia", "label": "Religión & Teología", "category": "libros_y_bd" },
      { "option": "revistas_y_magazines", "label": "Revistas & Magazines", "category": "libros_y_bd" }
    ,
                          
      { "option": "accesorios_mujeres", "label": "Accesorios Mujeres", "category": "moda" },
      { "option": "accesorios_hombres", "label": "Accesorios Hombres", "category": "moda" },
      { "option": "otros_vestimenta_y_accesorios", "label": "Otros Vestimenta & Accesorios", "category": "moda" },
      { "option": "zapatos_mujeres", "label": "Zapatos Mujeres", "category": "moda" },
      { "option": "zapatos_hombres", "label": "Zapatos Hombres", "category": "moda" },
      { "option": "disfraces_de_carnaval_y_fiestas_tematicas", "label": "Disfraces de Carnaval y Fiestas Temáticas", "category": "moda" },
      { "option": "trajes_folkloricos", "label": "Trajes Folklóricos", "category": "moda" },
      { "option": "boda", "label": "Boda", "category": "moda" },
      { "option": "bolsos_mujeres", "label": "Bolsos Mujeres", "category": "moda" },
      { "option": "maletas", "label": "Maletas", "category": "moda" },
      { "option": "ropa_mujeres", "label": "Ropa Mujeres", "category": "moda" },
      { "option": "ropa_hombres", "label": "Ropa Hombres", "category": "moda" }
    ,
                              
                          
      { "option": "accesorios", "label": "Accesorios", "category": "modelismo_y_hobby" },
      { "option": "baterias_cargadores_y_bloques_de_alimentacion", "label": "Baterías, Cargadores & Bloques de Alimentación", "category": "modelismo_y_hobby" },
      { "option": "circuitos", "label": "Circuitos", "category": "modelismo_y_hobby" },
      { "option": "control_remoto", "label": "Control Remoto", "category": "modelismo_y_hobby" },
      { "option": "electronica", "label": "Electrónica", "category": "modelismo_y_hobby" },
      { "option": "kits_de_montaje", "label": "Kits de Montaje", "category": "modelismo_y_hobby" },
      { "option": "literatura_y_catalogos", "label": "Literatura & Catálogos", "category": "modelismo_y_hobby" },
      { "option": "motores", "label": "Motores", "category": "modelismo_y_hobby" },
      { "option": "herramientas_y_accesorios", "label": "Herramientas & Accesorios", "category": "modelismo_y_hobby" },
      { "option": "rc_kits_de_montaje", "label": "RC Kits de Montaje", "category": "modelismo_y_hobby" },
      { "option": "trenes_electricos", "label": "Trenes Eléctricos", "category": "modelismo_y_hobby" },
      { "option": "vitrina", "label": "Vitrina", "category": "modelismo_y_hobby" },
      { "option": "coches_modelos", "label": "Coches Modelos", "category": "modelismo_y_hobby" }
    ,
                          
                          
      { "option": "accesorios", "label": "Accesorios", "category": "monedas" },
      { "option": "billetes_de_banco", "label": "Billetes de Banco", "category": "monedas" },
      { "option": "cartas_con_moneda", "label": "Cartas con Moneda", "category": "monedas" },
      { "option": "monedas_cantonales", "label": "Monedas Cantonales", "category": "monedas" },
      { "option": "monedas_europeas", "label": "Monedas Europeas", "category": "monedas" },
      { "option": "monedas_suizas", "label": "Monedas Suizas", "category": "monedas" },
      { "option": "monedas_internacionales", "label": "Monedas Internacionales", "category": "monedas" },
      { "option": "medallas", "label": "Medallas", "category": "monedas" },
      { "option": "metales_preciosos", "label": "Metales Preciosos", "category": "monedas" },
      { "option": "todas_las_monedas", "label": "Todas las Monedas", "category": "monedas" },
      { "option": "valores_historicos", "label": "Valores Históricos", "category": "monedas" }
    ,

                          
      { "option": "accesorios", "label": "Accesorios", "category": "relojes_y_joyas" },
      { "option": "otros_joyas", "label": "Otros Joyas", "category": "relojes_y_joyas" },
      { "option": "anillos", "label": "Anillos", "category": "relojes_y_joyas" },
      { "option": "joyas_para_piercing", "label": "Joyas para Piercing", "category": "relojes_y_joyas" },
      { "option": "pendientes", "label": "Pendientes", "category": "relojes_y_joyas" },
      { "option": "pulseras", "label": "Pulseras", "category": "relojes_y_joyas" },
      { "option": "broches_y_agujas", "label": "Broches & Agujas", "category": "relojes_y_joyas" },
      { "option": "collares", "label": "Collares", "category": "relojes_y_joyas" },
      { "option": "relojes", "label": "Relojes", "category": "relojes_y_joyas" },
      { "option": "relojes_de_lujo", "label": "Relojes de Lujo", "category": "relojes_y_joyas" },
      { "option": "relojes_de_marcas", "label": "Relojes de Marcas", "category": "relojes_y_joyas" },
      { "option": "relojes_tendencia", "label": "Relojes Tendencia", "category": "relojes_y_joyas" },
      { "option": "objetos_de_esoterismo_y_religion", "label": "Objetos de Esoterismo y de Religión", "category": "relojes_y_joyas" },
      { "option": "colgantes", "label": "Colgantes", "category": "relojes_y_joyas" },
      { "option": "relojes_de_pared_y_pendulos", "label": "Relojes de Pared & Péndulos", "category": "relojes_y_joyas" },
      { "option": "piedras_preciosas", "label": "Piedras Preciosas", "category": "relojes_y_joyas" },
      { "option": "despertadores", "label": "Despertadores", "category": "relojes_y_joyas" }
    ,
    
      { "option": "accesorios", "label": "Accesorios", "category": "musica_y_instrumentos" },
      { "option": "articulos_para_fans", "label": "Artículos para Fans", "category": "musica_y_instrumentos" },
      { "option": "baterias_y_percusiones", "label": "Baterías & Percusiones", "category": "musica_y_instrumentos" },
      { "option": "cds", "label": "CDs", "category": "musica_y_instrumentos" },
      { "option": "cassettes_audio", "label": "Cassettes Audio", "category": "musica_y_instrumentos" },
      { "option": "iluminacion_y_efectos_de_luz", "label": "Iluminación & Efectos de Luz", "category": "musica_y_instrumentos" },
      { "option": "equipo_dj", "label": "Equipo DJ", "category": "musica_y_instrumentos" },
      { "option": "equipo_de_estudio", "label": "Equipo de Estudio", "category": "musica_y_instrumentos" },
      { "option": "instrumentos_de_cuerda_y_pulsados", "label": "Instrumentos de Cuerda & Pulsados", "category": "musica_y_instrumentos" },
      { "option": "instrumentos_de_musica", "label": "Instrumentos de Música", "category": "musica_y_instrumentos" },
      { "option": "instrumentos_de_teclado", "label": "Instrumentos de Teclado", "category": "musica_y_instrumentos" },
      { "option": "instrumentos_de_viento", "label": "Instrumentos de Viento", "category": "musica_y_instrumentos" },
      { "option": "partituras_y_recopilaciones_de_canciones", "label": "Partituras & Recopilaciones de Canciones", "category": "musica_y_instrumentos" },
      { "option": "mesas_de_mejclas", "label": "Mesas de Mezclas", "category": "musica_y_instrumentos" },
      { "option": "vinilos", "label": "Vinilos", "category": "musica_y_instrumentos" }
    ,
    
      { "option": "armarios", "label": "Armarios", "category": "menaje_y_hogar" },
      { "option": "otros_articulos_para_la_casa", "label": "Otros Artículos para la Casa", "category": "menaje_y_hogar" },
      { "option": "bancos", "label": "Bancos", "category": "menaje_y_hogar" },
      { "option": "aparador", "label": "Aparador", "category": "menaje_y_hogar" },
      { "option": "escritorios", "label": "Escritorios", "category": "menaje_y_hogar" },
      { "option": "sillas", "label": "Sillas", "category": "menaje_y_hogar" },
      { "option": "dormitorio", "label": "Dormitorio", "category": "menaje_y_hogar" },
      { "option": "aire_acondicionado", "label": "Aire Acondicionado", "category": "menaje_y_hogar" },
      { "option": "tocadores", "label": "Tocadores", "category": "menaje_y_hogar" },
      { "option": "cómodas", "label": "Cómodas", "category": "menaje_y_hogar" },
      { "option": "cojin", "label": "Cojín", "category": "menaje_y_hogar" },
      { "option": "mantas", "label": "Mantas", "category": "menaje_y_hogar" },
      { "option": "cocinar_y_guisar", "label": "Cocinar & Guisar", "category": "menaje_y_hogar" },
      { "option": "cocinas", "label": "Cocinas", "category": "menaje_y_hogar" },
      { "option": "disenos_clasicos", "label": "Diseños Clásicos", "category": "menaje_y_hogar" },
      { "option": "carritos", "label": "Carritos", "category": "menaje_y_hogar" },
      { "option": "divanes_y_tumbonas", "label": "Divanes & Tumbonas", "category": "menaje_y_hogar" },
      { "option": "decoracion", "label": "Decoración", "category": "menaje_y_hogar" },
      { "option": "sillones", "label": "Sillones", "category": "menaje_y_hogar" },
      { "option": "lamparas_y_iluminacion", "label": "Lámparas & Iluminación", "category": "menaje_y_hogar" },
      { "option": "maquinas_de_cafe_y_expresso", "label": "Máquinas de Café & Espresso", "category": "menaje_y_hogar" },
      { "option": "muebles_para_tv", "label": "Muebles para TV", "category": "menaje_y_hogar" },
      { "option": "espejos", "label": "Espejos", "category": "menaje_y_hogar" },
      { "option": "perchero_cava_y_estanterias", "label": "Perchero, Cava & Estanterías", "category": "menaje_y_hogar" },
      { "option": "refrigeradores_y_congeladores", "label": "Refrigeradores & Congeladores", "category": "menaje_y_hogar" },
      { "option": "bano", "label": "Baño", "category": "menaje_y_hogar" },
      { "option": "conjunto_de_mesa_y_sillas", "label": "Conjunto de Mesa y Sillas", "category": "menaje_y_hogar" },
      { "option": "sofas", "label": "Sofás", "category": "menaje_y_hogar" },
      { "option": "mesas", "label": "Mesas", "category": "menaje_y_hogar" },
      { "option": "taburetes", "label": "Taburetes", "category": "menaje_y_hogar" },
      
      { "option": "alfombras_y_cortinas", "label": "Alfombras & Cortinas", "category": "menaje_y_hogar" },
      { "option": "utensilios_y_aparatos_domesticos", "label": "Utensilios & Aparatos Domésticos", "category": "menaje_y_hogar" },
      { "option": "vajillas_y_cristalerias", "label": "Vajillas & Cristalerías", "category": "menaje_y_hogar" },
      { "option": "vitrinas", "label": "Vitrinas", "category": "menaje_y_hogar" },
      { "option": "estanterias", "label": "Estanterías", "category": "menaje_y_hogar" }
    ,
    
      { "option": "accesorios", "label": "Accesorios", "category": "ordenadores_y_redes" },
      { "option": "adaptadores_cables_y_fichas", "label": "Adaptadores, Cables & Fichas", "category": "ordenadores_y_redes" },
      { "option": "aplicaciones", "label": "Aplicaciones", "category": "ordenadores_y_redes" },
      { "option": "otros_accesorios_para_ordenadores", "label": "Otros Accesorios para Ordenadores", "category": "ordenadores_y_redes" },
      { "option": "baterias", "label": "Baterías", "category": "ordenadores_y_redes" },
      { "option": "cajas", "label": "Cajas", "category": "ordenadores_y_redes" },
      { "option": "cd_y_dvd_virgenes", "label": "CD & DVD Vírgenes", "category": "ordenadores_y_redes" },
      { "option": "tarjetas_graficas", "label": "Tarjetas Gráficas", "category": "ordenadores_y_redes" },
      { "option": "placas_base", "label": "Placas Base", "category": "ordenadores_y_redes" },
      { "option": "tarjetas_de_sonido", "label": "Tarjetas de Sonido", "category": "ordenadores_y_redes" },
      { "option": "cartuchos_y_toner", "label": "Cartuchos & Tóner", "category": "ordenadores_y_redes" },
      { "option": "cargador", "label": "Cargador", "category": "ordenadores_y_redes" },
      { "option": "teclados", "label": "Teclados", "category": "ordenadores_y_redes" },
      { "option": "coin_miner", "label": "Coin Miner", "category": "ordenadores_y_redes" },
      { "option": "componentes_electronicos", "label": "Componentes Electrónicos", "category": "ordenadores_y_redes" },
      { "option": "controladores", "label": "Controladores", "category": "ordenadores_y_redes" },
      { "option": "fundas_y_bolsas", "label": "Fundas & Bolsas", "category": "ordenadores_y_redes" },
      { "option": "discos_duros", "label": "Discos Duros", "category": "ordenadores_y_redes" },
      { "option": "docking_station", "label": "Docking Station", "category": "ordenadores_y_redes" },
      { "option": "pantallas", "label": "Pantallas", "category": "ordenadores_y_redes" },
      { "option": "filamento", "label": "Filamento", "category": "ordenadores_y_redes" },
      { "option": "grabadoras_de_cd_y_dvd", "label": "Grabadoras de CD & DVD", "category": "ordenadores_y_redes" },
      { "option": "hubs_y_switches", "label": "Hubs & Switches", "category": "ordenadores_y_redes" },
      { "option": "impresora_3d", "label": "Impresora 3D", "category": "ordenadores_y_redes" },
      { "option": "impresoras", "label": "Impresoras", "category": "ordenadores_y_redes" },
      { "option": "modems_y_routers", "label": "Módems & Routers", "category": "ordenadores_y_redes" },
      { "option": "memorias_ram", "label": "Memorias / RAM", "category": "ordenadores_y_redes" },
      { "option": "organizadores_pda", "label": "Organizadores PDA", "category": "ordenadores_y_redes" },
      { "option": "pcs", "label": "PCs", "category": "ordenadores_y_redes" },
      { "option": "pare_fuego", "label": "Pare-Fuego", "category": "ordenadores_y_redes" },
      { "option": "portatiles", "label": "Portátiles", "category": "ordenadores_y_redes" },
      { "option": "racks_y_soportes", "label": "Racks & Soportes", "category": "ordenadores_y_redes" },
      { "option": "escaneres", "label": "Escáneres", "category": "ordenadores_y_redes" },
      { "option": "servidores", "label": "Servidores", "category": "ordenadores_y_redes" },
      { "option": "ratones", "label": "Ratones", "category": "ordenadores_y_redes" },
      { "option": "streamer", "label": "Streamer", "category": "ordenadores_y_redes" },
      { "option": "soporte_de_pantalla", "label": "Soporte de Pantalla", "category": "ordenadores_y_redes" },
      { "option": "tabletas_pc", "label": "Tabletas PC", "category": "ordenadores_y_redes" },
      { "option": "wifi", "label": "WiFi", "category": "ordenadores_y_redes" }
    ,
    
      { "option": "accesorios", "label": "Accesorios", "category": "fotografia_y_optica" },
      { "option": "camara_de_accion", "label": "Cámara de Acción", "category": "fotografia_y_optica" },
      { "option": "camara_fotografica", "label": "Cámara Fotográfica", "category": "fotografia_y_optica" },
      { "option": "diapositivas", "label": "Diapositivas", "category": "fotografia_y_optica" },
      { "option": "laboratorio_y_cuarto_oscuro", "label": "Laboratorio & Cuarto Oscuro", "category": "fotografia_y_optica" },
      { "option": "optica", "label": "Óptica", "category": "fotografia_y_optica" },
      { "option": "prestaciones_de_servicio", "label": "Prestaciones de Servicio", "category": "fotografia_y_optica" }
    ,
    
      { "option": "otros_cuidados_y_cosmeticos", "label": "Otros Cuidados & Cosméticos", "category": "cuidado_y_cosmetica" },
      { "option": "cosmeticos_naturales", "label": "Cosméticos Naturales", "category": "cuidado_y_cosmetica" },
      { "option": "gafas", "label": "Gafas", "category": "cuidado_y_cosmetica" },
      { "option": "manicura_y_pedicura", "label": "Manicura & Pedicura", "category": "cuidado_y_cosmetica" },
      { "option": "maquillaje", "label": "Maquillaje", "category": "cuidado_y_cosmetica" },
      { "option": "perfumes_y_agua_de_tocador", "label": "Perfumes & Agua de Tocador", "category": "cuidado_y_cosmetica" },
      { "option": "salud_y_bienestar", "label": "Salud & Bienestar", "category": "cuidado_y_cosmetica" },
      { "option": "cuidado_dental", "label": "Cuidado Dental", "category": "cuidado_y_cosmetica" },
      { "option": "cuidado_del_cabello", "label": "Cuidado del Cabello", "category": "cuidado_y_cosmetica" },
      { "option": "cuidado_del_cuerpo", "label": "Cuidado del Cuerpo", "category": "cuidado_y_cosmetica" },
      { "option": "cuidado_del_rostro", "label": "Cuidado del Rostro", "category": "cuidado_y_cosmetica" },
      { "option": "tatuajes_y_accesorios", "label": "Tatuajes y Accesorios", "category": "cuidado_y_cosmetica" }
    ,
    
      { "option": "alimentacion_deportiva", "label": "Alimentación Deportiva", "category": "deporte" },
      { "option": "alpinismo_y_marcha", "label": "Alpinismo & Marcha", "category": "deporte" },
      { "option": "otros_accesorios", "label": "Otros Accesorios", "category": "deporte" },
      { "option": "otros_deportes", "label": "Otros Deportes", "category": "deporte" },
      { "option": "balones", "label": "Balones", "category": "deporte" },
      { "option": "porterias_de_futbol_y_hockey", "label": "Porterías de Fútbol & Hockey", "category": "deporte" },
      { "option": "camping", "label": "Camping", "category": "deporte" },
      { "option": "cascos", "label": "Cascos", "category": "deporte" },
      { "option": "calzado", "label": "Calzado", "category": "deporte" },
      { "option": "ciclismo", "label": "Ciclismo", "category": "deporte" },
      { "option": "equitacion", "label": "Equitación", "category": "deporte" },
      { "option": "fitness_y_entrenamiento_en_casa", "label": "Fitness & Entrenamiento en Casa", "category": "deporte" },
      { "option": "fijaciones", "label": "Fijaciones", "category": "deporte" },
      { "option": "futbol", "label": "Fútbol", "category": "deporte" },
      { "option": "guantes", "label": "Guantes", "category": "deporte" },
      { "option": "golf", "label": "Golf", "category": "deporte" },
      { "option": "botellas", "label": "Botellas", "category": "deporte" },
      { "option": "hockey_sobre_hielo", "label": "Hockey sobre Hielo", "category": "deporte" },
      { "option": "karting", "label": "Karting", "category": "deporte" },
      { "option": "kite", "label": "Kite", "category": "deporte" },
      { "option": "minimotos", "label": "Minimotos", "category": "deporte" },
      { "option": "patines_de_hielo", "label": "Patines de Hielo", "category": "deporte" },
      { "option": "patines_y_patines_en_linea", "label": "Patines & Patines en Línea", "category": "deporte" },
      { "option": "tablas_de_surf_y_natacion", "label": "Tablas de Surf y Natación", "category": "deporte" },
      { "option": "buceo", "label": "Buceo", "category": "deporte" },
      { "option": "protecciones", "label": "Protecciones", "category": "deporte" },
      { "option": "pesca", "label": "Pesca", "category": "deporte" },
      { "option": "raquetas", "label": "Raquetas", "category": "deporte" },
      { "option": "bolsas", "label": "Bolsas", "category": "deporte" },
      { "option": "esqui", "label": "Esquí", "category": "deporte" },
      { "option": "snowboard", "label": "Snowboard", "category": "deporte" },
      { "option": "deportes_de_combate", "label": "Deportes de Combate", "category": "deporte" },
      { "option": "deportes_divertidos", "label": "Deportes Divertidos", "category": "deporte" },
      { "option": "tenis", "label": "Tenis", "category": "deporte" },
      { "option": "todo_para_los_fans", "label": "Todo para los Fans", "category": "deporte" }
    ,
                   
    
      { "option": "accesorios", "label": "Accesorios", "category": "sellos" },
      { "option": "otros_sellos", "label": "Otros Sellos", "category": "sellos" },
      { "option": "internacional", "label": "Internacional", "category": "sellos" },
      { "option": "lotes_y_colecciones", "label": "Lotes & Colecciones", "category": "sellos" },
      { "option": "onu", "label": "ONU", "category": "sellos" },
      { "option": "pre_filatelia", "label": "Pre-Filatelia", "category": "sellos" },
      { "option": "replica", "label": "Réplica", "category": "sellos" },
      { "option": "tematicos", "label": "Temáticos", "category": "sellos" },
      { "option": "sellos_ch", "label": "Sellos CH", "category": "sellos" },
      { "option": "sellos_de_correo_aereo", "label": "Sellos de Correo Aéreo", "category": "sellos" },
      { "option": "sellos_de_soldados", "label": "Sellos de Soldados", "category": "sellos" },
      { "option": "sellos_tasa_y_de_servicio", "label": "Sellos-Tasa y de Servicio", "category": "sellos" },
      { "option": "todos_los_sellos", "label": "Todos los Sellos", "category": "sellos" },
      { "option": "variedades", "label": "Variedades", "category": "sellos" }
    ,
    
      { "option": "accesorios_smartwatch", "label": "Accesorios Smartwatch", "category": "telefonia_y_radiocomunicacion" },
      { "option": "accesorios_para_moviles", "label": "Accesorios para Móviles", "category": "telefonia_y_radiocomunicacion" },
      { "option": "tarjeta_prepago", "label": "Tarjeta Prepago", "category": "telefonia_y_radiocomunicacion" },
      { "option": "tarjetas_de_memoria", "label": "Tarjetas de Memoria", "category": "telefonia_y_radiocomunicacion" },
      { "option": "moviles", "label": "Móviles", "category": "telefonia_y_radiocomunicacion" },
      { "option": "smartwatch", "label": "Smartwatch", "category": "telefonia_y_radiocomunicacion" },
      { "option": "tecnica_de_radiocomunicacion", "label": "Técnica de Radiocomunicación", "category": "telefonia_y_radiocomunicacion" },
      { "option": "telefonos_y_fax", "label": "Teléfonos & Fax", "category": "telefonia_y_radiocomunicacion" }
    ,
       
    
      { "option": "alimentos_y_especialidades", "label": "Alimentos & Especialidades", "category": "vinos_y_gastronomia" },
      { "option": "otros", "label": "Otros", "category": "vinos_y_gastronomia" },
      { "option": "otros_tabacos", "label": "Otros Tabacos", "category": "vinos_y_gastronomia" },
      { "option": "cervezas", "label": "Cervezas", "category": "vinos_y_gastronomia" },
      { "option": "encendedores_y_zippos", "label": "Encendedores & Zippos", "category": "vinos_y_gastronomia" },
      { "option": "cafes", "label": "Cafés", "category": "vinos_y_gastronomia" },
      { "option": "jarras_y_decantadores", "label": "Jarras & Decantadores", "category": "vinos_y_gastronomia" },
      { "option": "ceniceros", "label": "Ceniceros", "category": "vinos_y_gastronomia" },
      { "option": "champagne_y_prosecco", "label": "Champagne & Prosecco", "category": "vinos_y_gastronomia" },
      { "option": "puros", "label": "Puros", "category": "vinos_y_gastronomia" },
      { "option": "estantes_y_armarios_para_vinos", "label": "Estantes & Armarios para Vinos", "category": "vinos_y_gastronomia" },
      { "option": "humidificadores", "label": "Humidificadores", "category": "vinos_y_gastronomia" },
      { "option": "literatura", "label": "Literatura", "category": "vinos_y_gastronomia" },
      { "option": "narguiles", "label": "Narguiles", "category": "vinos_y_gastronomia" },
      { "option": "pipas_y_accesorios", "label": "Pipas & Accesorios", "category": "vinos_y_gastronomia" },
      { "option": "espirituosos", "label": "Espirituosos", "category": "vinos_y_gastronomia" },
      { "option": "tabaco_para_oler", "label": "Tabaco para Oler", "category": "vinos_y_gastronomia" },
      { "option": "tes", "label": "Tés", "category": "vinos_y_gastronomia" },
      { "option": "sacacorchos_y_tapones", "label": "Sacacorchos & Tapones", "category": "vinos_y_gastronomia" },
      { "option": "vasos", "label": "Vasos", "category": "vinos_y_gastronomia" },
      { "option": "vinos", "label": "Vinos", "category": "vinos_y_gastronomia" }
    ,
    
    
      { "option": "autos", "label": "Autos", "category": "vehiculos" },
      { "option": "otros_vehiculos", "label": "Otros Vehículos", "category": "vehiculos" },
      { "option": "motos", "label": "Motos", "category": "vehiculos" },
      { "option": "vehiculo_utilitario", "label": "Vehículo Utilitario", "category": "vehiculos" }
    
    
                          
    ],

    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Subcategory',
      group: 'primary',
    },
    showConfig: {
      label: 'Subcategory',
      isDetail: true,
    },
    saveConfig: {
      label: 'Subcategory',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a subcategory.',
    },
  },

  // {
  //   key: 'condition',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'in_box', label: 'In box' },
  //     { option: 'on_sprue', label: 'On sprue' },
  //     { option: 'unpainted', label: 'Unpainted' },
  //     { option: 'partially_painted_primed', label: 'Partially painted / Primed' },
  //     { option: 'fully_painted', label: 'Fully painted' },
  //     { option: 'damaged_incomplete', label: 'Damaged / Incomplete' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Condition',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Condition',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Condition',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a condition.',
  //   },
  // },
  {
    key: 'condition',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'new', label: 'New' },
      { option: 'used', label: 'Used' },
      { option: 'broken', label: 'Broken' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Condition',
      group: 'primary',
    },
    showConfig: {
      label: 'Condition',
      isDetail: true,
    },
    saveConfig: {
      label: 'Condition',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a condition.',
    },
  },

  // {
  //   key: 'itemUnitType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'package', label: 'Package' },
  //     { option: 'big_stuff', label: 'Big Stuff' },
  //     { option: 'whatever', label: 'Whatever unit type' },
  //     // { option: 'mounted_unit', label: 'Mounted Unit' },
  //     // { option: 'vehicle', label: 'Vehicle' },
  //     // { option: 'transport', label: 'Transport' },
  //     // { option: 'centerpiece', label: 'Centerpiece' },
  //     { option: 'n/a', label: 'N/A' },
  //   ],

  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Unit Type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Unit Type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Unit Type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a unit type.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'product-selling',
    label: 'Product',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'oneItem',
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
