import { FormattedDate, FormattedMessage } from 'react-intl';
import { priceData } from '../containers/ListingPage/ListingPage.shared';
import { useConfiguration } from '../context/configurationContext';
import { types as sdkTypes } from '../util/sdkLoader';
import * as validators from '../util/validators';
import { formatMoney } from './currency';

const { UUID, Money } = sdkTypes;

export const getHighestBidPriceLabel = (listing, intl) => {
  const config = useConfiguration();

  const mainPrice = listing?.attributes?.price;
  const highestBidAmount = listing?.attributes?.publicData?.highestBidAmount;
  const highestBigPrice = highestBidAmount && new Money(highestBidAmount, config.currency);

  const { formattedPrice } = priceData(highestBigPrice || mainPrice, config.currency, intl);
  return 'Highest bid: ' + formattedPrice;
};

export const getBidMinimumAmount = (listing) => {
  if (!listing)
    return null;

  const highestBidAmount = listing?.attributes?.publicData?.highestBidAmount;
  return highestBidAmount ? highestBidAmount : listing?.attributes?.price.amount
};

export const getBidsListLabel = (listing, intl) => {
  const config = useConfiguration();

  const bids = listing?.attributes?.publicData?.bids || [];

  if (bids.length > 1) {
    bids.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }

  const timeFormatOptions =
  {
    month: 'short',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  };

  return bids.length > 0 ?
    <div>
      <FormattedMessage id="AuctionPanel.bidsHistory" />
      <ul>
        {bids.map((bid, index) => (
          <li key={index}>
            <div>
              {priceData(new Money(bid.amount, config.currency), config.currency, intl)?.formattedPrice}
              {' - '}
              <FormattedDate value={new Date(bid.createdAt)} {...timeFormatOptions} />
            </div>

          </li>
        ))}
      </ul>
    </div>
    : null;

};

export const getCustomPriceValidators = (
  listingMinimumPriceSubUnits,
  marketplaceCurrency,
  intl
) => {
  const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingAndStockForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};
